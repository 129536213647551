
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import store from "@/store";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "motor-insurance-claims-settlement-offer",
  components: { Form, Field, GoBackButton },
  props: ["publicId"],
  data() {
    return {
      // publicId: this.$route.params.publicId,
      claim: {},
      additionalComments: "",
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const displayDeclineField = ref(false);

    const router = useRouter();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const acceptSettlementOffer = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            formData.append("isAccepted", "true");
            formData.append("additionalComments", "");

            ApiService.setHeader();
            ApiService.post(`/settlement/${props.publicId}/respond`, formData)
              .then((response) => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Settlement offer was accepted successfully.",
                  "success"
                );
                // Proceed to discharge voucheer page
                router.push({
                  name: "building-house-insurance-claims-discharge-voucher",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const declineSettlementOffer = (values, { resetForm }) => {
      if (submitButton2.value) {
        // Activate indicator
        submitButton2.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton2.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            formData.append("isAccepted", "false");
            formData.append("additionalComments", values["additionalComments"]);

            ApiService.setHeader();
            ApiService.post(`/settlement/${props.publicId}/respond`, formData)
              .then(() => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Settlement offer declined successfully",
                  "success"
                );
                resetForm();
                // Proceed to discharge voucheer page
                router.push({
                  name: "client-claims-history",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settlement Offer", [
        "Claims",
        "House Insurance Claim",
      ]);
    });

    return {
      submitButton1,
      submitButton2,
      acceptSettlementOffer,
      declineSettlementOffer,
      user,
      displayDeclineField,
    };
  },
  created() {
    this.getClaimInformation();

    //Set page title
    document.title =
      "House Insurance Claim: Settlement Offer | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CLAIMS_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            if (
              data.data.claimNumber == "" ||
              data.data.settlementOffer == null
            ) {
              variables.toastAlert(
                "Missing claim number or settlement offer amount",
                "error"
              );
              router.go(-1);
            }

            //Assign data to variables
            this.claim = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(amount) {
      return variables.addCommaToNumber(amount);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    downloadClaimDocument(id, uniqueFileName) {
      const submitButton1 = document.getElementById("download-doc");

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        // Delete cart item
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(
            `${variables.CLAIMS_ROUTE}/${this.publicId}/document/${uniqueFileName}`
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              // console.log(response.data);
              const blob = new Blob([response.data], {
                type: "application/octet-stream",
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = uniqueFileName;
              // link.download = `${fileName}.${extension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              submitButton1.removeAttribute("data-kt-indicator");
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
  },
});
